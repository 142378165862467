import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/SummaryScreen.scss';
import Card from "../components/Card/Card";
import AnimatedElement from "../components/AnimatedElement/AnimatedElement";
import Button from "../components/Button/Button";

const ANIMATION_SPEED = 1000;

export default class SummaryScreen extends Component {
  static propTypes = {
    data: PropTypes.array,
    fullPoints: PropTypes.number,
    maxPoints: PropTypes.number,
    canRepeatExercises: PropTypes.bool,
    calculatePercentage: PropTypes.bool,
    percentageToPass: PropTypes.number,

    replayAction: PropTypes.func,
    nextAction: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    fullPoints: 0,
    maxPoints: 0,
    canRepeatExercises: true,

    replayAction: () => {},
    nextAction: () => {},

    calculatePercentage: false,
    percentageToPass: 50,
  };

  fullPoints = 0
  maxPoints = 0

  constructor(props) {
    super(props);

    this.fullPoints = Object.values(props.data).reduce((previous, current) => {
      return previous + parseInt(current.points, 10)
    }, 0)
    this.maxPoints = Object.values(props.data).reduce((previous, current) => {
      return previous + parseInt(current.maxPoints, 10)
    }, 0)

    this.state = {
      visible: true,
    };
  }

  goNext = (action, levelNumber) => {
    if (this.state.visible) {
      this.setState({
        visible: false,
      });

      if (action === 'next')
        setTimeout(this.props.nextAction, ANIMATION_SPEED);
      else if (action === 'replay')
        setTimeout(this.props.replayAction, ANIMATION_SPEED, levelNumber);
    }
  };

  render() {
    const {calculatePercentage, percentageToPass, canRepeatExercises} = this.props;
    const percentage = Math.round((this.fullPoints / this.maxPoints) * 100);
    const didPass = percentage >= percentageToPass;

    let summary = this.props.data.map((levelInfo, index) => {
      if (parseInt(levelInfo.points) === 0 && parseInt(levelInfo.maxPoints) === 0) {
        return ''
      }

      return (
        <Card className="summary-row" key={index}>
          <Card className="score">
            <p className="name">
              { levelInfo.name }
            </p>
            <p className="points">
              { levelInfo.points }{levelInfo.maxPoints > 0 ? ' / ' + levelInfo.maxPoints : ''}
            </p>
          </Card>
          {canRepeatExercises &&
            <Button onClick={this.goNext.bind(this, 'replay', levelInfo.levelNumber)}>
              Popraw wynik
            </Button>
          }
        </Card>
      );
    });

    return (
      <AnimatedElement className="SummaryScreen" visible={this.state.visible}>
        <Card className="header">
          <h1>Podsumowanie</h1>
        </Card>
        {(canRepeatExercises || !calculatePercentage) &&
        <div className="summary">
          {summary}
        </div>
        }
        {this.maxPoints > 0 &&
          <Card className={'score-total'} color={Card.COLORS.BRIGHT}>
            <p className="name">
              Razem:
            </p>
            <p className="points">
              {this.fullPoints} / {this.maxPoints}
            </p>
          </Card>
        }
        { this.maxPoints > 0 && calculatePercentage &&
          <Card className="percentage-total" color={didPass ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}>
            <p className="percentage-count">
              Twój wynik to {percentage}% punktów.
            </p>
            <p className="pass-info">
              {didPass ? "Test zaliczony. Brawo!" : "Test do poprawy. Przygotuj się lepiej."}
            </p>
          </Card>
        }
          <div className="pure-g buttons-container">
            <div className="pure-u-1-1 center">
              <Button onClick={this.goNext.bind(this, 'next')} big>
                Zakończ
              </Button>
            </div>
          </div>
      </AnimatedElement>
    );
  }
}
