import React, { Component } from 'react';
import _ from 'lodash';
import axios from "axios";

import QuestionExercise from '../screens/QuestionExercise';
import InformationScreen from '../screens/InformationScreen';
import SprintBar from "../components/SprintBar";
import VideoScreen from "../screens/VideoScreen";
import ModalScreen from "../screens/ModalScreen";
import LoginScreen from "../screens/LoginScreen";
import MemoryExercise from "../screens/MemoryExercise/MemoryExercise";
import ShootingExercise from "../screens/ShootingExercise/ShootingExercise";
import OldMatchExercise from "../screens/MatchExercise";
import QuestionsExercise from "../screens/QuestionsExercise";
import OldOpenQuestionExercise from "../screens/OpenQuestionExercise";
import OpenQuestionExercise from "../screens/OpenQuestionExercise/OpenQuestionExercise";
import DefinitionExercise from "../screens/DefinitionExercise";
import MessageScreen from "../screens/MessageScreen";
import NewMessageScreen from "../screens/MessageScreen/MessageScreen";
import SummaryScreen from "../screens/SummaryScreen";
import MemoryMatchExercise from "../screens/MemoryMatchExercise";
import BasketsExercise from '../screens/BasketsExercise';
import OldQuestionExercise from "../screens/unused/OldQuestionsExercise";
import RiseFallExercise from "../screens/RiseFallExercise/RiseFallExercise";

import PlayArea from "../lib/PlayArea";
import SendResultsScreen from "../screens/SendResultsScreen";
import Results from "../structures/Results";
import Animation, {ANIMATION_TYPES} from "../components/Animation";
import AnimatedElement from '../components/AnimatedElement/AnimatedElement';
import QuizExercise, {QUIZ_CORRECT_POINTS} from "../screens/QuizExercise/QuizExercise";
import TrainExercise, {POINTS_FOR_FIRST_ANSWER} from "../screens/TrainExercise/TrainExercise";
import MeditationExercise from "../screens/MeditationExercise";
import ChoicesExercise from "../screens/ChoicesExercise/ChoicesExercise";
import RevealExercise from "../screens/RevealExercise/RevealExercise";
import ElevatorExercise from "sprint/screens/ElevatorExercise/ElevatorExercise";
import DoorsExercise from "../screens/DoorsExercise/DoorsExercise";
import MatchExercise from "../screens/MatchExercise/MatchExercise";

const FIXED_PARAMETERS = {
  token: 'bubbles#35143',
  shouldRequireLogin: false,
  shouldRequirePassword: false,
  shouldPlayInLoop: false,
  shouldSendResults: true,
  shouldShowSummary: false,
  // exerciseGroups: EXAMPLE_CONFIG,
};

const USE_FIXED_PARAMETERS = false;

export const WIDTH_EMS = 80;
export const WIDTH_EMS_VERTICAL = 50;
export const DEFAULT_ANIMATION_SPEED = 1000;

// export const SERVER_ADDRESS = "https://zlotetarasy.s.sprin.tech/";
// export const SERVER_ADDRESS = 'https://phytopharm.s.sprin.tech/';
// export const SERVER_ADDRESS = 'https://s4.sprintechlearning.com/';
export const SERVER_ADDRESS = 'https://kpmg.s.sprin.tech/';
// export const SERVER_ADDRESS = 'http://192.168.0.4:8888/';
export const INSTANCE_NAME = 'default';


// DO NOT DELETE! Needed to make global hotkey to skip components
let CHANGE_COMPONENT = null;

const STARTING_LEVEL = 0;

const LOGIN_COMPONENT = {
  type: 'login',
  parameters: {
    helpText: 'Wprowadź nazwę użytkownika i hasło, które otrzymałeś.',
  }
};

const SUMMARY_COMPONENT = {
  id: 'summary',
  slug: 'summary',
  type: 'summary',

  parameters: {
    helpText: 'Na tym ekranie możesz obejrzeć swoje wyniki z całego sprintu. Jeśli chcesz powtórzyć któreś ze ćwiczeń, kliknij na przycisk "Powtórz ćwiczenie" w wierszu z danym ćwiczeniem.',
  }
};

const SEND_RESULTS_COMPONENT = {
  id: 'send_results',
  type: 'send_results',
  parameters: {
    helpText: 'Wysyłamy statystyki - poczekaj, aż zakończymy. W razie problemów wybierz opcję "Spróbuj ponownie" lub skontaktuj się z nami.',
  }
};

const DEFAULT_FINISH_PARAMETERS = {
  image: 'finish',
  header: 'Dziękujemy!',
  content: 'To już koniec - dziękujemy za poświęcony czas. Mamy nadzieję, że spędziłeś go miło i pożytecznie.',
  buttonText: 'Odśwież',
  refreshOnNext: true,
  helpText: 'To już koniec! Możesz zamknąć okno przeglądarki.',

  // imageUrl: "/images/zlote-tarasy-akademia-kariery.jpg",
  // imageFull: true,
  // buttonText: "Przejdź do Akademii Kariery",
  // customUrlForNext: "https://akademiakarierynazlotej.pl/",
};

const ALREADY_PLAYED_COMPONENT = {
  id: 'message_already_played',
  slug: 'message',
  parameters: {
    header: 'Zakończono',
    image: 'finish',
    content: 'Ta sesja jest już zakończona - nie możesz jej przejść jeszcze raz.',
    buttonText: 'Odśwież',

    helpText: 'To już koniec! Możesz zamknąć okno przeglądarki.',
    refreshOnNext: true,
  }
}

class SprintController extends Component {
  instanceName = INSTANCE_NAME
  sessionToken = ''
  doubleTapControlled = false
  touchScrolling = false
  lastTouchEnd = 0

  canRepeatExercises = null
  instanceScore = []

  constructor(props) {
    super(props)

    this.state = {
      initialised: false,

      forcedComponent: false,

      maxHeight: 0,
      height: 0,
      width: 0,
      fontSize: 0,

      loggedIn: null,
      passwordRequired: null,
      playInLoop: null,
      shouldSendResults: null,
      shouldShowSummary: null,
      generateLogin: false,
      exerciseGroups: [],
      otherParameters: {},

      finishParameters: DEFAULT_FINISH_PARAMETERS,

      helpVisible: false,
      skipVisible: false,
      points: {},
      otherResults: {},
      currentPoints: 0,

      maxPoints: 0,

      level: STARTING_LEVEL,
      sprintFinished: false,
      replaying: false,
      showFinish: false,
      showSummary: false,
      sendResults: false,
    };

    if (props.match.params.instanceName) {
      // FIXME - używać instanceName z propsów zamiast kopiować
      this.instanceName = props.match.params.instanceName
    }

    this.disableTapZooming()

    this._onLoggedIn = this._onLoggedIn.bind(this);
    this._onNextScreen = this._onNextScreen.bind(this);
    this._onResize = this._onResize.bind(this);
    this._getExerciseComponent = this._getExerciseComponent.bind(this);

    CHANGE_COMPONENT = this.changeComponent;
  }

  disableTapZooming = () => {
    document.addEventListener('touchmove', (event) => {
      if (!this.doubleTapControlled && !this.targetScrollable(event.target)) {
        event.preventDefault()
      }
    }, { passive : false });

    document.addEventListener('touchend', (event) => {
      if (!this.doubleTapControlled) {
        let now = (new Date()).getTime();
        if (now - this.lastTouchEnd <= 300) {
          event.preventDefault();
        } else {
          this.lastTouchEnd = now;
        }
      }
    },  { passive : false });
  };
  
  targetScrollable = (target) => {
    return target.classList.contains('scrollable') || target.parentElement.classList.contains('scrollable')
      || target.parentElement.parentElement.classList.contains('scrollable')
      || target.parentElement.parentElement.parentElement.classList.contains('scrollable')
  };

  _getSprintOptions = () => {
    let username = window.localStorage.getItem('username');
    let token = window.localStorage.getItem('token');

    if (USE_FIXED_PARAMETERS) {
      console.log('!!! WARNING: Using fixed parameters !!!')
      this.instanceScore = 0
      setTimeout(this._parametersLoaded.bind(this, FIXED_PARAMETERS), 1000)
    } else {
      axios({
        method: 'POST',
        url: SERVER_ADDRESS + 'get-active-session',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        data: {
          'username': username,
          'userToken': token,
          'instanceName': this.instanceName // TODO: Read instance name from json
        }
      }).then((result) => {
        if (result.data['status'] === 'Success') {
          // SUCCESS
          let parameters = result.data['parameters']
          this.instanceScore = result.data['instanceScore']
          this._parametersLoaded(parameters)
        } else if (result.data['status'] === 'Already played') {
          this.setState({
            forcedComponent: ALREADY_PLAYED_COMPONENT,
            initialised: true,
          })
          this.changeComponent(false)
        } else {
          // ERROR
          console.log('error')
          // TODO Handle error
        }
      }).catch((error) => {
        // CONNECTION ERROR
        console.log('connection error')
        console.log(error)
        // TODO Handle connection error
      });
    }
  };

  _parametersLoaded = (parameters) => {
    let maxPoints = 0;
    let points = {};
    let levelIndex = 0;

    for (let exercise of parameters['exercises']) {
      let currentPoints = {
        current: 0,
        max: 0,
      };
      
      if (exercise.parameters && !exercise.parameters.tutorial) {
        let pointsForExercise = this._calculatePointsForExercise(exercise);
        currentPoints['max'] = pointsForExercise;
        maxPoints += pointsForExercise;
      }
      
      points[levelIndex] = currentPoints;
      levelIndex++;
    }

    this.sessionToken = parameters['token'];
    if (this.instanceScore.length > 0) {
      this.canRepeatExercises = false
    } else {
      this.canRepeatExercises = parameters['canRepeatExercises']
    }

    let otherParameters = parameters['other'] ? parameters['other'] : {};

    this.setState({
      initialised: true,

      loggedIn: !parameters['shouldRequireLogin'] && !parameters['shouldSendResults'],
      passwordRequired: parameters['shouldRequirePassword'],
      generateLogin: !parameters['shouldRequireLogin'] && parameters['shouldSendResults'],
      playInLoop: parameters['shouldPlayInLoop'],
      shouldSendResults: parameters['shouldSendResults'],
      shouldShowSummary: parameters['shouldShowSummary'],
      exerciseGroups: parameters['exercises'],

      otherParameters,

      points: points,
      maxPoints: maxPoints,
    })

    this.changeComponent(false);
  }

  _getComponent = (state = this.state) => {
    if (state.forcedComponent) {
      return state.forcedComponent
    } else if (!state.exerciseGroups) {
      return false;
    } else if (!state.loggedIn) {
      return LOGIN_COMPONENT;
    } else if (state.sprintFinished && !state.replaying) {
      if (state.showFinish) {
        return this._getFinishComponentOptions();
      } else if (state.sendResults && this.canRepeatExercises) {
        return SEND_RESULTS_COMPONENT;
      } else if (state.showSummary) {
        return SUMMARY_COMPONENT;
      } else if (state.sendResults) {
        return SEND_RESULTS_COMPONENT;
      }  else {
        return this._getFinishComponentOptions();
      }
    } else {
      return state.exerciseGroups[state.level];
    }
  };

  _getFinishComponentOptions = () => {
    return {
      id: 'message_finish',
      slug: 'message',
      parameters: this.state.finishParameters,
    }
  };

  _calculatePointsForExercise = (exercise) => {
    let type = exercise['slug'];
    let parameters = exercise.parameters;
    let count = 0;
    
    switch (type) {
      case 'memory':
      case 'question':
        return 10;
      case 'baskets':
        return parameters.questions.length * 2;
      case 'definition':
        return parameters.questions.length;
      case 'quiz':
        return exercise.questions.length * QUIZ_CORRECT_POINTS;
      case 'shooting':
        if (!exercise.parameters.noPoints) {
          for (let question of exercise.questions) {
            for (let answer of question.answers) {
              if (answer.correct) {
                count += 2;
              }
            }
          }
        }
        return count;
      case 'train':
        return exercise.questions.length * (exercise.parameters.pointsForFirstAnswer ? exercise.parameters.pointsForFirstAnswer : POINTS_FOR_FIRST_ANSWER);
      case 'rise_fall':
        for (let question of exercise.questions) {
          count += question.answers.length
        }
        return count;
      case 'memory_match':
        return parameters.answers.length;
      case 'match':
        return MatchExercise.maxPoints(exercise.questions);
      case 'questions':
        for (let question of parameters.questions)
          count += question.subquestions.length;
        return count;
      case 'reveal':
        return RevealExercise.maxPoints(exercise.questions);
      case 'elevator':
        return ElevatorExercise.maxPoints(exercise.questions);
      case 'doors':
        return DoorsExercise.maxPoints(exercise.questions);
      case 'open_question':
      default:
        return 0;
    }
  };

  /**
   * Add event listener
   */
  componentDidMount() {
    this._getSprintOptions()
    this._onResize();
    window.addEventListener("resize", this._onResize);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this._onResize);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.fontSize !== nextState.fontSize) {
      document.documentElement.style.fontSize = `${nextState.fontSize}px`;
    }
    return true;
  }

  render() {
    let component
    if (this.state.initialised) {
      component = this._getComponent();
    } else {
      return ''
    }

    return (
      <div id="main" className={"main SprintController " + (PlayArea.isVertical() ? 'vertical' : 'horizontal')} style={{'fontSize': this.state.fontSize}}>
        <div className={"outer "  + (component['slug']) } style={{'height': window.innerHeight}}>
          <AnimatedElement visible={this.state.helpVisible}>
              <ModalScreen
                onClose={this.closeHelp} description={component ? (component.parameters.helpText ? component.parameters.helpText : component.helpText ): ''} title="Pomoc"
                maxWidth={this.state.width} maxHeight={this.state.height}/>
          </AnimatedElement>
          <AnimatedElement visible={this.state.skipVisible}>
            <ModalScreen
              title="Zakończ" description="Czy na pewno chcesz opuścić ćwiczenie? Punkty nie zostaną zapisane."
              button={{message: 'Zakończ ćwiczenie', action: this.changeComponent.bind(this, true, true)}}
              onClose={this.closeSkip} maxWidth={this.state.width} maxHeight={this.state.height}/>
          </AnimatedElement>
          <div className="middle">
            <div className="inner scrollable" style={{'width': this.state.width, 'height': this.state.height}}>
              {this._getExerciseComponent(component)}
            </div>
          </div>
        </div>
        <Animation active={this.state.loggedIn} type={ANIMATION_TYPES.fade}>
          <SprintBar
            width={this.state.width} helpClick={this.openHelp} skipClick={this.openSkip}
            points={this.state.currentPoints} maxPoints={this.state.maxPoints}
            level={this.state.level + 1} maxLevels={this.state.exerciseGroups.length}
          />
        </Animation>
      </div>
    );
  }

  changeComponent = (next = true, finish = false) => {
    let level = this.state.level;
    let replaying = this.state.replaying;
    let sprintFinished = this.state.sprintFinished;
    let sendResults = this.state.sendResults;
    let showFinish = this.state.showFinish;
    let showSummary = this.state.showSummary;

    if (this.state.loggedIn) {
      if (next) {
        if (finish) {
          sprintFinished = true;
        }else {
          if (this.state.replaying) {
            replaying = false;
            level = this.state.exerciseGroups.length - 1;
          } else if (level < this.state.exerciseGroups.length - 1) {
            level++;
          } else {
            if (!sprintFinished) {
              sprintFinished = true;
              if ((!this.state.shouldShowSummary || !this.canRepeatExercises) && this.state.shouldSendResults && !sendResults) {
                sendResults = true;
              } else {
                showSummary = true;
              }
            } else if (this.state.shouldSendResults && !sendResults) {
              sendResults = true;
            } else if (this.state.shouldShowSummary && !showSummary) {
              showSummary = true;
            } else if (!this.state.playInLoop) {
              showFinish = true;
            } else {
              sprintFinished = false;
              sendResults = false;
              this._loop();
            }

            level = this.state.exerciseGroups.length - 1;
          }
        }
      }
    }

    this.setState({
      level: level,

      showSummary,
      replaying: replaying,
      sprintFinished: sprintFinished,
      sendResults: sendResults,
      showFinish: showFinish,
    });
  };

  closeHelp = () => {
    this.setState({
      helpVisible: false,
    });
  };

  openHelp = () => {
    this.setState({
      helpVisible: true,
    });
  };

  closeSkip = () => {
    this.setState({
      skipVisible: false,
    });
  };

  openSkip = () => {
    this.setState({
      skipVisible: true,
    });
  };
  
  getCurrentExercise = () => {
    return this.state.exerciseGroups[this.state.level];
  }
  
  _replayExercise = (levelNumber) => {
    this.setState((prevState) => {
      prevState.currentPoints -= prevState.points[levelNumber].current;
      prevState.points[levelNumber].current = 0;

      return {
        currentPoints: prevState.currentPoints,
        points: prevState.points,

        replaying: true,
        level: parseInt(levelNumber, 10),
      }
    });
    this.changeComponent(false);
  };

  _loop = () => {
    this.setState((prevState) => {
      for (let pointsIndex in prevState.points) {
        if (prevState.points.hasOwnProperty(pointsIndex)) {
          prevState.points[pointsIndex].current = 0;
        }
      }

      return {
        currentPoints: 0,
        points: prevState.points,

        level: STARTING_LEVEL,
        sprintFinished: false,
        sendResults: false,
        replaying: false,
      };
    });
  };

  _onLoggedIn(username) {
    this.setState({
      loggedIn: true,
    });
    this.changeComponent(false);
  }

  _onNextScreen(results) {
    let currentExercise = this.getCurrentExercise()
    
    if (!_.isObject(results)) {
      let newResults = new Results();
      newResults.points = results;
      results = newResults;
    }

    if (results.points && results.points > 0) {
      this.setState((prevState) => {
        prevState.points[prevState.level].current += results.points;
        return {
          points: prevState.points,
          currentPoints: prevState.currentPoints + results.points,
        }
      });
    }

    if (results.other) {
      this.setState((prevState) => {
        prevState.otherResults[currentExercise['id']] = results.other;

        return {
          otherResults: prevState.otherResults,
        }
      });
    }

    this.changeComponent();
  }

  _generateExercisesSummary = () => {
    if (this.instanceScore.length > 0) {
      return [{name: 'Wynik w tej sesji', points: this.state.currentPoints, maxPoints: this.state.maxPoints}].concat(this.instanceScore)
    } else {
      return this._generateExercisesPoints()
    }
  }

  _generateExercisesPoints = () => {
    let summary = [];
    let points = this.state.points;
    for (let levelIndex in points) {
      if (points.hasOwnProperty(levelIndex)) {
        if (points[levelIndex].max > 0) {
          summary.push({
            levelNumber: levelIndex,
            points: points[levelIndex].current,
            maxPoints: points[levelIndex].max,
            name: this.state.exerciseGroups[levelIndex].name,
          })
        }
      }
    }

    return summary;
  }

  _generateShortPointsSummaryString = () => {
    let shortSummary = "";
    let summary = this._generateExercisesSummary();
    for (let exercise of summary) {
      shortSummary += exercise.name + ': ' + exercise.points + ' / ' + exercise.maxPoints + '<br>';
    }

    return shortSummary;
  };

  _onResize() {
    if (PlayArea.isVertical()) {
      this._resizeVertical();
    } else {
      this._resizeHorizontal();
    }
  }

  _resizeVertical = () => {
    let newWidth = window.innerWidth;
    let newHeight = window.innerHeight;
    let maxHeight = window.innerHeight - 50;

    if (newWidth > newHeight * 2 / 3) {
      newWidth = newHeight * 2 / 3;
    } else {
      newHeight = newWidth * 3 / 2;
    }

    newWidth -= 10;
    newHeight -= 15;

    this.setState({
      'maxHeight': maxHeight,
      'width': newWidth,
      'height': newHeight,
      'fontSize': newWidth / PlayArea.widthInEms(),
    })
  };

  _resizeHorizontal = () => {
    let newWidth = window.innerWidth;
    let newHeight = window.innerHeight;
    let maxHeight = window.innerHeight - 50;

    if (newWidth > newHeight * 4 / 3) {
      newWidth = newHeight * 4 / 3;
    } else {
      newHeight = newWidth * 3 / 4;
    }

    newWidth -= 12;
    newHeight -= 9;

    this.setState({
      'maxHeight': maxHeight,
      'width': newWidth,
      'height': newHeight,
      'fontSize': newWidth / PlayArea.widthInEms(),
    })
  };

  _getExerciseComponent(component) {
    this.doubleTapControlled = false;

    if (!component) {
      return;
    }

    if (component.type === 'login') {
      return <LoginScreen
        loginAction={this._onLoggedIn}
        serverAddress={SERVER_ADDRESS}

        onlyUsernameNeeded={!this.state.passwordRequired}
        generateLoginIfNeeded={this.state.generateLogin}
      />
    } else if (component.type === 'summary') {
      let calculatePercentage, percentageToPass;
      if (this.state.otherParameters.summary) {
        calculatePercentage = this.state.otherParameters.summary.calculatePercentage;
        percentageToPass = this.state.otherParameters.summary.percentageToPass;
      }

      return <SummaryScreen
        data={this._generateExercisesSummary()}
        fullPoints={this.state.currentPoints}
        maxPoints={this.state.maxPoints}
        canRepeatExercises={this.canRepeatExercises}

        calculatePercentage={calculatePercentage}
        percentageToPass={percentageToPass}

        nextAction={this._onNextScreen}
        replayAction={this._replayExercise}

      />
    } else if (component.type === 'send_results') {
      return <SendResultsScreen
        serverAddress={SERVER_ADDRESS}

        exercisesPoints={this._generateExercisesPoints()}
        sessionToken={this.sessionToken}
        instanceName={this.instanceName}
        otherContent={this.state.otherResults}


        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'question') {
      return <QuestionExercise
        question={component.parameters.question}
        answers={component.parameters.answers}
        answersType={component.parameters.answersType}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'information') {
      return <InformationScreen
        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'video') {
      return <VideoScreen
        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'memory') {
      return <MemoryExercise
        key={component.id}
        questions={component.questions}
        parameters={component.parameters}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'message') {
      return <NewMessageScreen
        parameters={component.parameters}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'message') {
      return <MessageScreen
        parameters={component.parameters}
        image={component.parameters.image}
        header={component.parameters.header}
        content={component.parameters.content}

        html={component.parameters.html}
        imageFormat={component.parameters.imageFormat}
        bigImage={component.parameters.bigImage}
        buttonText={component.parameters.buttonText}

        browserWarning={component.parameters.browserWarning}
        forVisa={component.parameters.forVisa}
        nextEnabled={component.parameters.nextEnabled}
        refreshEnabled={component.parameters.refreshEnabled}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'shooting') {
      this.touchScrolling = true;
      
      return <ShootingExercise
        parameters={component.parameters}
        questions={component.questions}
        tutorial={component.tutorial}
        tutorialMessages={component.parameters.tutorialMessages}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'match') {
      return <OldMatchExercise
        instruction={component.parameters.instruction}
        answers={component.parameters.answers}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'baskets') {
      return <BasketsExercise
        instruction={component.parameters.instruction}
        answers={component.parameters.answers}
        questions={component.parameters.questions}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'questions') {
      return <QuestionsExercise
        instruction={component.parameters.instruction}
        answers={component.parameters.answers}
        questions={component.parameters.questions}

        timeForQuestion={component.parameters.timeForQuestion}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'quiz') {
      let timePerQuestionSeconds;
      if (component.parameters['timePerQuestionSeconds'] !== undefined) {
        timePerQuestionSeconds = parseInt(component.parameters['timePerQuestionSeconds']);
      }
      return <QuizExercise
        questions={component.questions}
        parameters={component.parameters}

        timePerQuestionSeconds={timePerQuestionSeconds}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'open_question') {
      return <OldOpenQuestionExercise
        instructionPhrases={component.parameters.instructionPhrases}
        answers={component.parameters.answers}
        instructionDescription={component.parameters.instructionDescription}
        pointsString={this._generateShortPointsSummaryString()}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'definition') {
      return <DefinitionExercise
        instruction={component.parameters.instruction}
        answers={component.parameters.answers}
        questions={component.parameters.questions}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'memory_match') {
      return <MemoryMatchExercise
        instruction={component.parameters.instruction}
        answers={component.parameters.answers}
        tutorial={component.tutorial}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'old_question_exercise') {
      return <OldQuestionExercise
        answers={component.parameters.answers}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'rise_fall') {
      return <RiseFallExercise
        instruction={component.parameters.instruction}
        
        questions={component.questions}
        tutorial={component.parameters.tutorial}
        tutorialMessages={component.parameters.tutorialMessages}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'train') {
      return <TrainExercise
        parameters={component.parameters}
        questions={component.questions}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component.type === 'meditation') {
      this.doubleTapControlled = true;

      return <MeditationExercise
        duration={component.parameters.duration}

        key={component.id}

        goNextAction={this._onNextScreen}
      />
    } else if (component['slug'] === 'choices') {
      return <ChoicesExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else if (component['slug'] === 'reveal') {
      return <RevealExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else if (component['slug'] === 'elevator') {
      return <ElevatorExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else if (component['slug'] === 'doors') {
      return <DoorsExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else if (component['slug'] === 'open-question') {
      return <OpenQuestionExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else if (component['slug'] === 'match') {
      return <MatchExercise
        questions={component.questions}
        parameters={component.parameters}

        key={component.id}

        onFinish={this._onNextScreen}
      />
    } else {
      return '';
    }
  }
}

function doc_keyUp(e) {
  // testing for alt + n
  if (e.altKey && e.keyCode === 78) {
    CHANGE_COMPONENT();
  }
}
// register the handler
document.addEventListener('keyup', doc_keyUp, false);

export default SprintController;
