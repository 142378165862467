import React from 'react'
import _ from 'lodash';
import classNames from 'classnames';

import MyComponent from "sprint/base/MyComponent";
import PointsBar from "sprint/components/PointsBar/PointsBar";
import AnimatedElement from "sprint/components/AnimatedElement/AnimatedElement";
import Button from "sprint/components/Button/Button";
import InstructionCard from "sprint/components/InstructionCard/InstructionCard";
import Sounds from "sprint/lib/Sounds";
import FeedbackCard from "sprint/components/FeedbackCard/FeedbackCard";

import ElevatorDoors from "./subcomponents/ElevatorDoors";
import ElevatorButtons from "./subcomponents/ElevatorButtons";
import "./ElevatorExercise.scss";
import PropTypes from "prop-types";
import {DEFAULT_ANIMATION_SPEED} from "sprint/controllers/SprintController";
import Card from "../../components/Card/Card";

const STATES = {
  STARTING: 0,
  QUESTION_STARTING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_CHANGING: 4,
  ALL_QUESTIONS_ANSWERED: 5,
  FINISHED: 100,
};

const POINTS_PER_QUESTION = 2;
const DEFAULT_TIME_LIMIT_S = 30;

const AUTOMATIC_LAST_ANSWER_TIME_S = 20;

const FEEDBACK_TIME_MS = 5000;

export default class ElevatorExercise extends MyComponent {
  nextFloorTimeout;
  timeForFloorMs;

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
      automaticMode: PropTypes.bool, // Do floors change automatically
    }),

    onFinish: PropTypes.func,
  };

  static maxPoints(questions) {
    return questions.length * POINTS_PER_QUESTION;
  }

  constructor(props) {
    super(props);

    this.state = {
      questionIndex: 0,
      points: 0,
      answers: [],
      currentAnswerIndex: undefined,

      timeLimit: props.parameters.timePerQuestionSeconds ? parseInt(props.parameters.timePerQuestionSeconds) : DEFAULT_TIME_LIMIT_S,
      automaticMode: !!props.parameters.automaticMode,

      automatic: {
        canSkipFloors: true,
        lastChance: false,
      },

      feedback: {
        show: false,
        successful: false,
        content: '',
      }
    };


    MyComponent.myConstructor(props, this.state, STATES.STARTING);
  }

  render() {
    const {questions} = this.props;
    const {points, questionIndex, answers, feedback, currentAnswerIndex, timeLimit, automaticMode, automatic} = this.state;

    return (
      <AnimatedElement visible={!this.inState(STATES.FINISHED)} className={classNames("ElevatorExercise", {"automatic": automaticMode})}>

        <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={500}>
          <Button onClick={this.startExercise} big>
            Rozpocznij
          </Button>
        </AnimatedElement>

        <PointsBar visible={!this.inStates([STATES.STARTING, STATES.FINISHED])}
          points={points} maxPoints={ElevatorExercise.maxPoints(questions)}
          clockId={questionIndex} clockRunning={this.inState(STATES.QUESTION_ANSWERING)} timeLimit={timeLimit}
          clockWarningSeconds={timeLimit / 3} onTimeRanOut={this.timeRanOut}
        />
        <InstructionCard visible={this.inStates([STATES.QUESTION_STARTING, STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          countType="Pytanie" countCurrent={questionIndex + 1} countMax={questions.length}
          mainText={questions[questionIndex].content}
        />

        <AnimatedElement className="answers" visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])} zIndex={-2}>
          <ElevatorButtons currentFloor={currentAnswerIndex} floorCount={answers.length}
            changeFloorAction={this.setCurrentAnswerAction} goToNextFloor={this.goToNextFloor}
            floorButtonsEnabled={!automaticMode || automatic.lastChance} nextButtonVisible={automaticMode} nextButtonEnabled={automatic.canSkipFloors}
          />
          <ElevatorDoors
            answers={answers} currentFloor={currentAnswerIndex}
            onAnswerChosenAction={this.onAnswerChosenAction}
          />
        </AnimatedElement>

        <AnimatedElement className="fixed" visible={this.inState(STATES.QUESTION_STARTING)} animation={AnimatedElement.AnimationTypes.popOut}>
          <Button big onClick={this.startElevator}>Wsiądź do windy</Button>
        </AnimatedElement>

        <AnimatedElement className="fixed" visible={this.inState(STATES.QUESTION_ANSWERING) && automatic.lastChance}
          animation={AnimatedElement.AnimationTypes.popOut}
        >
          <Card className="last-chance" color={Card.COLORS.BRIGHT}>To Twoja ostatnia szansa! Wybierz piętro z prawidłową odpowiedzią.</Card>
        </AnimatedElement>

        <FeedbackCard content={feedback.content} visible={this.inState(STATES.QUESTION_ANSWERED) && feedback.show} successful={feedback.successful} />

        <AnimatedElement fullSize visible={this.inState(STATES.QUESTION_CHANGING)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={500}>
          <Button onClick={this.continueGame} big>
            Przejdź dalej
          </Button>
        </AnimatedElement>

      </AnimatedElement>
    )
  }

  startExercise = () => {
    this.prepareAnswers();

    if (this.state.automaticMode) {
      this.setCurrentState(STATES.QUESTION_STARTING);
    } else {
      this.setCurrentState(STATES.QUESTION_ANSWERING);
    }
  };

  startElevator = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERING, () => {
      this.goToNextFloor();
    });
  };

  prepareAnswers = () => {
    const {questions} = this.props;
    const {questionIndex, timeLimit} = this.state;

    let answers = _.shuffle(questions[questionIndex].answers).map((answer) => {
      return {
        content: answer["content"],
        correct: answer["correct"],
        feedback: answer["parameters"]["feedback"],

        open: false,
      }
    });

    this.timeForFloorMs = ((timeLimit - AUTOMATIC_LAST_ANSWER_TIME_S) / answers.length) * 1000;

    this.setState({
      answers,
    })
  };

  goToNextFloor = () => {
    clearTimeout(this.nextFloorTimeout);

    this.setState((state) => {
      let {currentAnswerIndex, automatic} = state;
      if (currentAnswerIndex === undefined) {
        currentAnswerIndex = -1;
      }

      if (currentAnswerIndex + 1 < state.answers.length) {
        currentAnswerIndex++;
        if (state.automaticMode) {
          this.nextFloorTimeout = setTimeout(this.goToNextFloor, this.timeForFloorMs)
        }
      } else {
        currentAnswerIndex = undefined;
        automatic = {
          canSkipFloors: false,
          lastChance: true,
        }
      }

      return {
        currentAnswerIndex,
        automatic,
      }
    })
  };

  setCurrentAnswerAction = (currentAnswerIndex) => () => {
    this.setState((state) => {
      let {automatic} = state;
      if (state.automaticMode && automatic.lastChance) {
        automatic.lastChance = false;
      }

      return {
        currentAnswerIndex,
        automatic,
      }
    })
  };

  onAnswerChosenAction = (index) => () => {
    if (this.inState(STATES.QUESTION_ANSWERING)) {
      clearTimeout(this.nextFloorTimeout);

      this.setState((state) => {
        const answer = state.answers[index];
        if (answer.correct) {
          Sounds.success.play();
          state.points += POINTS_PER_QUESTION;
          state.feedback.successful = true;
        } else {
          Sounds.error.play();
          state.feedback.successful = false;
        }

        state.feedback.show = true;
        state.feedback.content = answer.feedback;

        return {
          points: state.points,
          feedback: state.feedback,
        }
      }, () => {
        this.setCurrentStateSequence([STATES.QUESTION_ANSWERED, STATES.QUESTION_CHANGING], FEEDBACK_TIME_MS);
      });
    }
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_CHANGING);
  };

  continueGame = () => {
    if (this.state.questionIndex + 1 < this.props.questions.length) {
      this.setState((state) => {
        state.questionIndex++;

        return {
          currentAnswerIndex: undefined,
          questionIndex: state.questionIndex,
          automatic: {
            canSkipFloors: true,
            lastChance: false,
          }
        }
      }, () => {
        this.prepareAnswers();

        if (this.state.automaticMode) {
          this.setCurrentState(STATES.QUESTION_STARTING);
        } else {
          this.setCurrentState(STATES.QUESTION_ANSWERING);
        }
      })
    } else {
      this.setCurrentState(STATES.FINISHED, this.finished, DEFAULT_ANIMATION_SPEED);
    }
  };

  finished = () => {
    const {onFinish} = this.props;
    const {points} = this.state;

    onFinish({
      points,
    })
  };
}