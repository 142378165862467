import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import classNames from 'classnames';
// import ReactMarkdown from 'react-markdown';

import Card, {ButtonCard} from "../../components/Card";
import BubbleCard, {BUBBLE_SIZE, BUBBLE_FLIGHT_DURATION} from "./subcomponents/BubbleCard";

import MyComponent from "../../base/MyComponent";
import PointsBar from "../../components/PointsBar/PointsBar";
import PlayArea from "../../lib/PlayArea";
import {emString} from "../../utils/styling";
import OverlayMessage from "../../components/OverlayMessage";
import Animation from "../../components/Animation";

import './ShootingExercise.scss'
import {DEFAULT_ANIMATION_SPEED} from "../../controllers/SprintController";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import Button from "../../components/Button/Button";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import Container from "../../components/Container/Container";

export const TIME_TO_READ_QUESTION = 3000;

const ANSWERS_HEIGHT_HORIZONTAL = 100;
const ANSWERS_HEIGHT_VERTICAL = 110;
// const ANSWERS_HEIGHT_HORIZONTAL = 50;
// const ANSWERS_HEIGHT_VERTICAL = 60;

const BUBBLE_FLIGHT_VARIANCE = 20;
const BUBBLE_PROXIMITY_MARGIN = 2;

const ANIMATION_SPEED = 1000;
const DEFAULT_TIME_LIMIT = 120;
const TIME_BETWEEN_QUESTIONS = ANIMATION_SPEED;

const POINTS_PER_ANSWER = 2;

const STATES = {
  STARTING: 0,
  PLAYING: 1,
  CHANGING_QUESTION: 2,
  SUMMARY: 3,
  TUTORIAL_SUMMARY: 4,
  FINISHED: 5,
  TUTORIAL: 100,
};

export default class ShootingExercise extends MyComponent {
  nextQuestionTimeout; nextAnswerTimeout;
  answersHeightEms;
  movingTimeouts = [];

  maxPoints = 0;
  minBubblePosition = 0;
  maxBubblePosition = 100;

  static propTypes = {
    questions: PropTypes.array,
    answers: PropTypes.array,
    tutorial: PropTypes.bool,
    tutorialMessages: PropTypes.array,
    timeLimit: PropTypes.number,

    showSummary: PropTypes.bool,

    goNextAction: PropTypes.func,
  };

  static defaultProps = {
    tutorial: false,
    tutorialMessages: [],
    timeLimit: DEFAULT_TIME_LIMIT,
    showSummary: false,
  };

  constructor(props) {
    super(props);
  
    this.minBubblePosition = 0;
    this.maxBubblePosition = PlayArea.widthInEms() - BUBBLE_SIZE;
    
    this.state = {
      current: STATES.STARTING,

      clockRunning: false,
      timeRanOut: false,
      points: 0,
      lastAnswerPosition: null,

      currentQuestionIndex: 0,
      currentAnswerIndex: 0,

      answers: this.generateAnswersForQuestion(props.questions[0]),
      availableAnswers: this.generateAnswersForQuestion(props.questions[0]),
      activeAnswers: {},
      correctAnswersCount: this.countCorrectAnswersForQuestion(props.questions[0]),

      chosenAnswers: {
        0: [],
      },

      tutorial: {
        active: props.tutorial,

        messages: props.tutorialMessages,
        currentStep: 0,
      },
  
      changed: 0,
    };

    this.maxPoints = 0;
    for (let question of this.props.questions) {
      this.maxPoints += this.countCorrectAnswersForQuestion(question) * POINTS_PER_ANSWER;
    }

    if (props.tutorial) {
      this.nextAnswerTimeout = setTimeout(this.nextAnswer, ANIMATION_SPEED * 2);
    }
  }

  generateAnswersForQuestion = (question) => {
    return _.shuffle(question.answers).map(this.initAnswer);
  };

  initAnswer = (answer) => {
    return {
      id: answer.id,

      visible: false,
      active: true,
      moving: false,
      showFeedback: false,

      content: answer.content,
      correct: answer.correct,
      parameters: answer.parameters,

      startX: 0,
      finishX: 0,
      timeout: null,
    };
  };

  countCorrectAnswersForQuestion = (question) => {
    let answers = question.answers;
    let correctAnswersCount = 0;
    for (let answer of answers) {
      if (answer.correct) {
        correctAnswersCount += 1;
      }
    }

    return correctAnswersCount;
  };
  
  componentDidMount() {
    const onResize = () => {
      this.minBubblePosition = 0;
      this.maxBubblePosition = PlayArea.widthInEms() - BUBBLE_SIZE;
      
      if (PlayArea.isHorizontal()) {
        this.answersHeightEms = ANSWERS_HEIGHT_HORIZONTAL;
      } else {
        this.answersHeightEms = ANSWERS_HEIGHT_VERTICAL;
      }
    };
    
    onResize();
    window.addEventListener("resize", onResize);
  }
  
  componentWillUnmount() {
    clearTimeout(this.nextAnswerTimeout);
    clearTimeout(this.nextQuestionTimeout);

    for (let timeout of this.movingTimeouts) {
      clearTimeout(timeout);
    }
    
    // Clean after mo.js
    document.querySelectorAll('[data-name=mojs-shape]').forEach((node) => {
      node.parentNode.removeChild(node);
    });
  }
  
  render() {
    let answers = Object.values(this.state.activeAnswers).map(this.renderBubble);
    
    let answersContainerStyle = {
      height: emString(this.answersHeightEms),
    };

    return (
      <div className="ShootingExercise">
        <PointsBar visible={!this.inState(STATES.FINISHED)}
          points={this.state.points} maxPoints={this.maxPoints} hidePoints={this.props.parameters.noPoints}
          timeLimit={_.defaultTo(this.props.parameters.timeLimit, DEFAULT_TIME_LIMIT)} clockRunning={this.state.current === STATES.PLAYING}
          onTimeRanOut={this.timeRanOut} clockWarningSeconds={10} clockId={this.state.currentQuestionIndex}
        />

        {/*
            Starting
        */}
        <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut}>
          {/* TODO: Obsługa komunikatu przy rozpoczynaniu gry */}
          <Button onClick={this.startGame} big>
            Rozpocznij zadanie
          </Button>
        </AnimatedElement>

        {/*
            Tutorial
            TODO: Obsługa tutoriala
         */}
         <AnimatedElement visible={this.inState([STATES.TUTORIAL, STATES.TUTORIAL_SUMMARY])}>
           <OverlayMessage
             messages={this.state.tutorial.messages} messageId={this.state.tutorial.currentStep} />
         </AnimatedElement>

        {/*
            Playing
        */}
        <InstructionCard visible={this.inState(STATES.PLAYING)}
          countType="Pytanie" countCurrent={this.state.currentQuestionIndex + 1} countMax={this.props.questions.length}
          mainText={this.getCurrentQuestion().content} markdown
        />
        <AnimatedElement className="answers-container" style={answersContainerStyle} visible={this.inState([STATES.PLAYING, STATES.TUTORIAL])}>
          {answers}
          <Animation type="fade" active={this.inState([STATES.PLAYING, STATES.TUTORIAL]) && !!this.props.parameters.canSkipQuestions}
                     delay_ms={TIME_TO_READ_QUESTION * 4} id={this.state.currentQuestionIndex}>
            <div className="buttons-container skip">
              <ButtonCard
                onClick={_.once(this.timeRanOut)}>
                Następne pytanie
              </ButtonCard>
            </div>
          </Animation>
        </AnimatedElement>

        {/*
            Summary
        */}
        <Animation type="fade" active={this.inState([STATES.SUMMARY, STATES.TUTORIAL_SUMMARY])} delay_ms={DEFAULT_ANIMATION_SPEED}>
          {this.inState(STATES.SUMMARY) && this.props.showSummary &&
          <Card classes='summary scrollable'>
            <h1>Twoje odpowiedzi</h1>
            { this.inState(STATES.SUMMARY) && !this.props.parameters.categorize && this.props.questions.map((question, questionIndex) => {
              return [
                <h2 key={questionIndex}>{question.contentSummary}</h2>,
                this.state.chosenAnswers[questionIndex].map((chosenAnswer, chosenAnswerIndex) => {
                  return <li key={chosenAnswerIndex}>{ chosenAnswer.content }</li>
                })
              ]
            })}
            { this.inState(STATES.SUMMARY) && this.props.parameters.categorize && this.props.questions.map((question, questionIndex) => {
              let categoriesInfo = Object.entries(this.getCategoriesInfoForQuestionNr(questionIndex));
              return [
                <h2 key={questionIndex}>{question.contentSummary}</h2>,
                <table key={`table-${questionIndex}`}>
                  <tbody>
                  {categoriesInfo.map(([categoryName, categoryData], categoryIndex) => {
                    let percentage = (categoryData.points / categoryData.maxPoints) * 100;

                    return (
                      <tr key={categoryIndex}>
                        <td className="category-name">{ categoryName }</td>
                        <td className="category-points">
                          <div className="category-percentage-container">
                            <div className="category-percentage" style={{width: `${percentage}%`}}>
                              {/*{ percentage > 10 && `${Math.round(percentage)}%` }*/}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              ]
            })}
          </Card>
          }

          <Container>
              <Button onClick={this.onNext} big>
                Przejdź dalej
              </Button>
          </Container>
        </Animation>
      </div>
    );
  }

  renderBubble = (answer) => {
    return <BubbleCard
      answer={answer}
      startPosition={{x: `${answer.startX}rem`, y: `${this.answersHeightEms}rem`}}
      finishPosition={{x: `${answer.finishX}rem`, y: `0rem`}}

      onMovementFinished={this.answerMoved}
      clickCallback={this.answerChosen}

      index={answer.id}
      key={`${this.state.currentQuestionIndex}-${answer.id}`}
    />
  };

  startGame = () => {
    let state = STATES.PLAYING;

    if (this.props.tutorial) {
      state = STATES.TUTORIAL;
    }

    this.setCurrentState(state, () => {
      this.nextAnswerTimeout = setTimeout(this.nextAnswer, TIME_TO_READ_QUESTION);
    });
  };

  nextAnswer = () => {
    if (this.inState(STATES.TUTORIAL)) {
      this.nextTutorialStep();
    } else if (this.inState(STATES.PLAYING)) {
      if (this.state.availableAnswers.length > 0) {
        this.setState((state) => {
          let newAnswer = state.availableAnswers.shift();
          newAnswer.startX = this.findStartX(state.lastAnswerPosition);
          newAnswer.finishX = this.findFinishX(newAnswer.startX);
          state.activeAnswers[newAnswer.id] = newAnswer;

          return {
            availableAnswers: state.availableAnswers,
            activeAnswers: state.activeAnswers,
            lastAnswerPosition: newAnswer.startX,
          }
        })
      }

      this.nextAnswerTimeout = setTimeout(this.nextAnswer, BUBBLE_FLIGHT_DURATION / 4);
    }
  };

  findStartX = (lastPositionX) => {
    let newPositionX = lastPositionX;
    let tries = 10;

    while ((Math.abs(newPositionX - lastPositionX) < (BUBBLE_SIZE + BUBBLE_PROXIMITY_MARGIN)) && tries > 0) {
      newPositionX = _.random(this.minBubblePosition, this.maxBubblePosition);
      tries--;
    }

    return newPositionX;
  };

  findFinishX = (startPositionX) => {
    let transformMin = Math.max(-BUBBLE_FLIGHT_VARIANCE, -startPositionX + this.minBubblePosition);
    let transformMax = Math.min(BUBBLE_FLIGHT_VARIANCE, this.maxBubblePosition - startPositionX);

    return startPositionX + _.random(transformMin, transformMax);
  };

  answerChosen = (answer, index, doubleClicked = false) => {
    if (!this.inState([STATES.PLAYING, STATES.TUTORIAL]) || !answer.active) {
      return;
    }

    if (this.inState(STATES.TUTORIAL)) {
      this.setState((prevState) => {
        prevState.tutorial.currentStep++;
        this.nextAnswerTimeout = setTimeout(this.nextAnswer.bind(this), 1000);

        return {
          tutorial: prevState.tutorial,
        }
      });
    }

    let pointChange = 0;
    let correctAnswersCountChange = 0;

    if (answer.correct) {
      pointChange = POINTS_PER_ANSWER;
      correctAnswersCountChange = -1;
    } else {
      pointChange = -1;
    }

    this.setState((state) => {
      let newCorrectAnswersCount = state.correctAnswersCount + correctAnswersCountChange;
      let newPoints = state.points + pointChange;
      if (newPoints < 0) {
        newPoints = 0;
      }

      let savedAnswer = { content: answer.content };
      if (this.props.parameters.categorize) {
        savedAnswer.category = answer.parameters.category;
        savedAnswer.value = doubleClicked ? 2 : 1;
      }

      state.activeAnswers[answer.id].active = false;

      state.chosenAnswers[state.currentQuestionIndex].push(savedAnswer);


      if (newCorrectAnswersCount <= 0) {
        for (let answer of state.answers) {
          answer.visible = false;
          answer.active = false;
        }
      }

      return {
        points: newPoints,
        correctAnswersCount: newCorrectAnswersCount,
        answers: state.answers,
        chosenAnswers: state.chosenAnswers,
        activeAnswers: state.activeAnswers
      }
    }, () => {
      if (this.state.correctAnswersCount === 0) {
        this.setCurrentState(STATES.CHANGING_QUESTION);
        this.nextQuestionTimeout = setTimeout(this.nextQuestion, TIME_BETWEEN_QUESTIONS);
      }
    });
  };

  answerMoved = (answer) => {
    this.setState((state) => {
      delete state.activeAnswers[answer.id];
      if (answer.active) {
        state.availableAnswers.push(answer);
      }

      return {
        availableAnswers: state.availableAnswers,
        activeAnswers: state.activeAnswers,
      }
    })
  };
  
  onNext = () => {
    this.setState({
      current: STATES.FINISHED,
    });
    
    setTimeout(this.goNext, ANIMATION_SPEED);
  };
  
  goNext = () => {
    this.props.goNextAction({
      points: this.props.parameters.noPoints ? 0 : this.state.points,
      other: {
        type: this.props.parameters.categorize ? 'shooting-detailed-categories' : 'shooting-detailed-answers',
        data: this.state.chosenAnswers
      },
    });
  };

  nextQuestion = () => {
    clearTimeout(this.nextAnswerTimeout);
    let newQuestionIndex = this.state.currentQuestionIndex + 1;

    if (newQuestionIndex >= this.props.questions.length) {
      this.setCurrentState(STATES.SUMMARY);
    } else {
      this.setState((state) => {
        let question = this.props.questions[newQuestionIndex];
        state.chosenAnswers[newQuestionIndex] = [];
        this.nextAnswerTimeout = setTimeout(this.nextAnswer, ANIMATION_SPEED * 2 + TIME_TO_READ_QUESTION);

        return {
          currentQuestionIndex: newQuestionIndex,
          answers: this.generateAnswersForQuestion(question),
          chosenAnswers: state.chosenAnswers,
          correctAnswersCount: this.countCorrectAnswersForQuestion(question),
        }
      })
    }
  };

  nextTutorialStep = () => {
    this.setState((prevState) => {
      let shownAnswer;
      let state = STATES.TUTORIAL;
    
      for (let answer of Object.values(prevState.answers)) {
        if ((prevState.tutorial.currentStep === 0 && answer.correct)
          || (prevState.tutorial.currentStep === 1 && !answer.correct)) {
          shownAnswer = answer;
        
          break;
        }
      }
      if (shownAnswer !== undefined) {
        shownAnswer.visible = true;
        shownAnswer.moving = true;
        shownAnswer.tutorialVisible = true;
      } else {
        state = STATES.TUTORIAL_SUMMARY
      }
    
      return {
        current: state,
        answers: prevState.answers,
      }
    });
  }
  
  timeRanOut = () => {
    this.setCurrentState(STATES.CHANGING_QUESTION, () => {
      this.setState((prevState) => {
        for (let answer of prevState.answers) {
          answer.visible = false;
          answer.active = false;
          clearTimeout(answer.timeout)
        }

        this.nextQuestionTimeout = setTimeout(this.nextQuestion, TIME_BETWEEN_QUESTIONS);

        return {
          // current: STATES.SUMMARY,
          timeRanOut: true,
        }
      });
    });
  };

  hideAnswer = (answerIndex) => {
    this.setState((prevState) => {
      // let timeout = setTimeout(this.stopMovingAnswer.bind(this, answerIndex), BUBBLE_FLIGHT_DURATION / 10);
      // this.movingTimeouts.push(timeout);
      prevState.answers[answerIndex].visible = false;
      // prevState.answers[answerIndex].timeout = timeout;

      return {
        answers : prevState.answers,
      }
    })
  };

  stopMovingAnswer = (answerIndex) => {
    this.setState((prevState) => {
      prevState.answers[answerIndex].moving = false;

      return {
        answers : prevState.answers,
        timer: null,
      }
    })
  };

  getCurrentQuestion = () => {
    return this.props.questions[this.state.currentQuestionIndex]
  }
  

  
  getCategoriesInfoForQuestionNr = (questionNumber) => {
    if (!this.props.parameters.categorize) {
      return {}
    } else {
      let question = this.props.questions[questionNumber];
      let chosenAnswers = this.state.chosenAnswers[questionNumber];
      let categoriesInfo = {}
      for (let answer of question.answers) {
        if (!categoriesInfo[answer.parameters.category]) {
          categoriesInfo[answer.parameters.category] = {
            points: 0,
            maxPoints:0,
          }
        }
  
        categoriesInfo[answer.parameters.category].maxPoints += 2;
      }
  
      for (let answer of chosenAnswers) {
        categoriesInfo[answer.category].points += answer.value;
      }
      
      return categoriesInfo
    }
  }
  
  nextAnswerIndex = (index) => {
    return (index + 1) % this.state.answers.length;
  }
}
