import React from 'react';
import PropTypes from 'prop-types';

import MyComponent from "sprint/base/MyComponent";
import AnimatedElement from "sprint/components/AnimatedElement/AnimatedElement";
import Sounds from "sprint/lib/Sounds";
import Button from "../../components/Button/Button";
import PointsBar from "../../components/PointsBar/PointsBar";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import Room from "./subcomponents/Room";
import processAnswers from "../../lib/answers";
import FeedbackCard from "../../components/FeedbackCard/FeedbackCard";

import "./DoorsExercise.scss";
import {DEFAULT_ANIMATION_SPEED} from "../../controllers/SprintController";

const DEFAULT_TIME_LIMIT_S = 30;

const STATES = {
  STARTING: 0,
  QUESTION_ANSWERING: 1,
  QUESTION_ANSWERED: 3,
  QUESTION_CHANGING: 4,
  ALL_QUESTIONS_ANSWERED: 5,
  FINISHED: 100,
};

export default class DoorsExercise extends MyComponent {

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
    }),

    onFinish: PropTypes.func,
  };

  static maxPoints(questions) {
    let maxPoints = 0;
    for (let question of questions) {
      maxPoints += question.answers.length - 1
    }
    return maxPoints;
  }

  constructor(props) {
    super(props);

    const {questions, parameters} = props;

    this.state = {
      points: DoorsExercise.maxPoints(questions),
      answers: [],
      chosenAnswer: undefined,

      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      feedback: {
        successful: false,
        content: "",
      },

      questionIndex: 0,
      timeLimit: parameters.timePerQuestionSeconds ? parameters.timePerQuestionSeconds : DEFAULT_TIME_LIMIT_S,
    };

    MyComponent.myConstructor(props, this.state)
  }

  render() {
    const {questions} = this.props;
    const {
      answers, chosenAnswer, isCorrectAnswerChosen, isIncorrectAnswerChosen,
      feedback, questionIndex, points, timeLimit
    } = this.state;

    return (
      <AnimatedElement className="DoorsExercise" visible>
        <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={500}>
          <Button onClick={this.start} big>
            Rozpocznij
          </Button>
        </AnimatedElement>

        <PointsBar visible={!this.inStates([STATES.STARTING, STATES.FINISHED])}
          points={points} maxPoints={DoorsExercise.maxPoints(questions)}
          clockId={questionIndex} clockRunning={this.inState(STATES.QUESTION_ANSWERING)} timeLimit={timeLimit}
          clockWarningSeconds={timeLimit / 3} onTimeRanOut={this.timeRanOut}
        />

        <InstructionCard visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          countType="Pytanie" countCurrent={questionIndex + 1} countMax={questions.length}
          mainText={questions[questionIndex].content}
        />

        <AnimatedElement className="room-container" visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED, STATES.QUESTION_CHANGING])}>
          <Room answers={answers} chosenAnswer={chosenAnswer} questionIndex={questionIndex}
            lights={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
            onKeyChosenAction={this.onAnswerChosenAction}
            isDoorOpen={isCorrectAnswerChosen} isDoorShaking={isIncorrectAnswerChosen}
            onDoorClicked={this.onAnswerConfirmed}
          />
        </AnimatedElement>

        <FeedbackCard content={feedback.content} visible={this.inState(STATES.QUESTION_ANSWERED)} successful={feedback.successful} />

        <AnimatedElement fullSize visible={this.inState(STATES.ALL_QUESTIONS_ANSWERED)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={500}>
          <Button onClick={this.finish} big>
            Przejdź dalej
          </Button>
        </AnimatedElement>
      </AnimatedElement>
    )
  }

  start = () => {
    this.prepareQuestion();
    this.setCurrentState(STATES.QUESTION_ANSWERING);
  };

  continue = () => {
    const {questionIndex} = this.state;
    const {questions} = this.props;

    if (questionIndex + 1 < questions.length) {
      this.setState({
        questionIndex: questionIndex + 1,
      }, () => {
        setTimeout(() => {
          this.prepareQuestion();
          this.setCurrentStateDelayed(STATES.QUESTION_ANSWERING, DEFAULT_ANIMATION_SPEED);
        }, 1000);
      });
    } else {
      this.setCurrentState(STATES.ALL_QUESTIONS_ANSWERED);
    }
  };

  finish = () => {
    this.setCurrentState(STATES.FINISHED, this.finished, DEFAULT_ANIMATION_SPEED);
  };

  finished = () => {
    const {onFinish} = this.props;
    const {points} = this.state;

    onFinish({
      points,
    })
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_CHANGING);
    this.continue();
  };


  onAnswerChosenAction = (chosenAnswer) => () => {
    this.setState((state) => {
      const {answers} = state;
      if (state.chosenAnswer !== undefined) {
        answers[state.chosenAnswer.id].chosen = false;
      }
      answers[chosenAnswer.id].chosen = true;

      return {
        answers,
        chosenAnswer,
        isIncorrectAnswerChosen: false,
      }
    })
  };

  onAnswerConfirmed = () => {
    if (this.state.chosenAnswer) {
      this.setState((state) => {
        const {answers, chosenAnswer, points} = state;
        let pointChange = 0;

        const isCorrectAnswerChosen = chosenAnswer.correct;
        if (isCorrectAnswerChosen) {
          Sounds.success.play();
        } else {
          Sounds.error.play();
          pointChange = -1;
        }

        const isIncorrectAnswerChosen = !isCorrectAnswerChosen;
        const feedback = {
          content: chosenAnswer.feedback,
          successful: isCorrectAnswerChosen,
        };

        answers[chosenAnswer.id] = {
          ...answers[chosenAnswer.id],
          active: false,
          chosen: false,
        };

        return {
          points: points + pointChange,
          answers,
          chosenAnswer: undefined,
          isCorrectAnswerChosen,
          isIncorrectAnswerChosen,
          feedback,
        }
      }, () => {
        const {isCorrectAnswerChosen} = this.state;
        if (isCorrectAnswerChosen) {
          this.setCurrentStateSequence([STATES.QUESTION_ANSWERED, STATES.QUESTION_CHANGING], 5000, this.continue);
        } else {
          this.setCurrentStateSequence([STATES.QUESTION_ANSWERED, STATES.QUESTION_ANSWERING], 5000);
        }
      })
    }
  };

  prepareQuestion = () => {
    const {questions} = this.props;
    const {questionIndex} = this.state;

    const currentAnswers = questions[questionIndex].answers;
    this.setState({
      chosenAnswer: undefined,
      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      answers: processAnswers(currentAnswers, DoorsExercise.prepareAnswer)
    })
  };

  static prepareAnswer(answer, index) {
    return {
      id: index,

      content: answer["content"],
      correct: answer["correct"],
      feedback: answer["parameters"]["feedback"],

      active: true,
      chosen: false,
    }
  }
}