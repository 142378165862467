import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MyComponent from "../../base/MyComponent";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import PointsBar from "../../components/PointsBar/PointsBar";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import Button from "../../components/Button/Button";
import MatchCategories from "./subcomponents/MatchCategories";
import MatchAnswers from "./subcomponents/MatchAnswers";

import "./MatchExercise.scss";
import Sounds from "../../lib/Sounds/Sounds";
import AnimationCorrectExplosion from "../../animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion";
import AnimationIncorrectExplosion from "../../animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion";
import FeedbackCard from "../../components/FeedbackCard/FeedbackCard";

const POINTS_PER_ANSWER = 2;
const DEFAULT_TIME_LIMIT_S = 60;

const DEFAULT_FEEDBACK_SUCCESS = "Świetnie! Prawidłowa odpowiedź.";
const DEFAULT_FEEDBACK_FAILURE = "Ojoj - niestety, to nieprawidłowa odpowiedź.";

const TIME_TO_READ_QUESTION_S = 3;
const TIME_TO_READ_FEEDBACK_S = 5;

const STATES = {
  STARTING: 0,
  STARTED: 1,
  ANSWERING: 2,
  SHOWING_FEEDBACK: 3,
  ALL_ANSWERED: 4,

  FINISHED: 100,
};

export default class MatchExercise extends MyComponent {
  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.object,

    onFinish: PropTypes.func,
  };

  static maxPoints(questions) {
    return questions[0].answers.length * POINTS_PER_ANSWER;
  }

  constructor(props) {
    super(props);

    function prepareAnswers(answers) {
      return _.shuffle(answers).map((answer, index) => ({
        index,
        content: answer.content,
        categoryId: answer.parameters.category,
        active: true,
      }))
    }

    const {questions, parameters} = this.props;

    const question = questions[0];

    this.state = {
      points: 0,
      maxPoints: MatchExercise.maxPoints(this.props.questions),

      timeLimit: parameters.timePerQuestionSeconds ? parameters.timePerQuestionSeconds : DEFAULT_TIME_LIMIT_S,
      question,
      answers: prepareAnswers(question.answers),

      draggedPosition: undefined,
      draggedAnswer: undefined,
      feedback: {
        position: undefined,
        correct: undefined,
        message: "",
      }
    };

    MyComponent.myConstructor(props, this.state)
  }



  render() {
    const {timeLimit, question, answers, draggedPosition, feedback} = this.state;

    return <AnimatedElement className="MatchExercise" visible={!this.inState(STATES.FINISHED)}>
      <PointsBar visible={this.inStates([STATES.STARTED, STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}
        points={this.state.points} maxPoints={this.state.maxPoints}
        timeLimit={timeLimit} clockRunning={this.inState(STATES.ANSWERING)}
        onTimeRanOut={this.gameFinished} clockWarningSeconds={Math.ceil(timeLimit / 4)}
        instruction="Przesuwaj karty na odpowiadające im obrazki"
      />

      <InstructionCard visible={this.inStates([STATES.STARTED, STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}
        mainText={question.content} markdown small
      />

      <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut}>
        <Button onClick={this.startGame} big>
          Rozpocznij
        </Button>
      </AnimatedElement>

      <AnimatedElement visible={this.inStates([STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}>
        <MatchCategories categories={question.parameters.categories} draggedPosition={draggedPosition} onCategoryHit={this.categorySelected} />
        <MatchAnswers answers={answers} setDraggedPosition={this.setDraggedPosition} />

        <AnimationCorrectExplosion fixed
          visible={this.inState(STATES.SHOWING_FEEDBACK) && feedback.correct}
          position={feedback.position}
        />
        <AnimationIncorrectExplosion fixed
          visible={this.inState(STATES.SHOWING_FEEDBACK) && !feedback.correct}
          position={feedback.position}
        />
        <FeedbackCard
          visible={this.inState(STATES.SHOWING_FEEDBACK)}
          content={feedback.message} successful={feedback.correct}
        />
      </AnimatedElement>
    </AnimatedElement>
  }

  startGame = () => {
    this.setCurrentStateSequence([STATES.STARTED, STATES.ANSWERING], TIME_TO_READ_QUESTION_S * 1000);
  };

  setDraggedPosition = (draggedPosition, draggedAnswer) => {
    this.setState({
      draggedPosition,
      draggedAnswer,
    })
  };

  categorySelected = (categoryId) => {
    const {draggedAnswer, draggedPosition} = this.state;
    let pointsChange = 0;
    let feedback = {
      position: draggedPosition,
      correct: false,
      message: DEFAULT_FEEDBACK_FAILURE,
    };

    if (draggedAnswer.categoryId === categoryId) {
      Sounds.success.play();
      pointsChange = POINTS_PER_ANSWER;
      feedback = {
        position: draggedPosition,
        correct: true,
        message: DEFAULT_FEEDBACK_SUCCESS,
      };

    } else {
      Sounds.error.play();
    }

    this.setState(state => {
      const answers = [...state.answers];
      const {draggedAnswer, points} = state;
      answers[draggedAnswer.index].active = false;

      return {
        feedback,
        draggedAnswer: undefined,
        draggedPosition: undefined,

        answers,
        points: points + pointsChange,
      }
    }, () => {
      this.setCurrentStateSequence([STATES.SHOWING_FEEDBACK, STATES.ANSWERING], TIME_TO_READ_FEEDBACK_S * 1000, this.checkIfFinished)
    });
  };

  checkIfFinished = () => {
    const {answers} = this.state;
    let finished = true;

    for (const answer of answers) {
      if (answer.active) {
        finished = false;
        break;
      }
    }

    if (finished) {
      this.gameFinished();
    }
  };

  gameFinished = () => {
    this.setCurrentStateSequence([STATES.ALL_ANSWERED, STATES.FINISHED], 1000, this.finish, 2000);
  };
}