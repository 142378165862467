import React, {Component} from "react";
import PropTypes from "prop-types";
import mojs from "mo-js";
import classNames from "classnames";

import {emString} from "../../../utils/styling";
import Sounds from "../../../lib/Sounds";

import BubbleDiv, {ANIMATION_POSES} from './BubbleDiv';
import './BubbleCard.scss'

export const BUBBLE_SIZE = 20;
export const BUBBLE_FLIGHT_DURATION = 15000;
const DOUBLE_TAP_INTERVAL_MS = 500;
const FEEDBACK_DURATION_MS = 2000;


export default class BubbleCard extends Component {
  circle; burst; burstDouble; doubleTapTimeout; doubleTapping = false;
  
  static propTypes = {
    question: PropTypes.string,
    answer: PropTypes.object,
    index: PropTypes.string,

    startPosition: PropTypes.shape({x: PropTypes.string, y: PropTypes.string}),
    finishPosition: PropTypes.shape({x: PropTypes.string, y: PropTypes.string}),

    onMovementFinished: PropTypes.func,
    
    clickCallback: PropTypes.func,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      pose: ANIMATION_POSES.moving,

      clicked: false,
    };
  }


  componentWillUnmount() {
    clearTimeout(this.doubleTapTimeout)
  }
  
  render() {
    const answer = this.props.answer;

    let style = {
      zIndex: answer.tutorialVisible ? '2000' : '3',
    };

    const bubbleContainerStyle = {
      width: emString(BUBBLE_SIZE),
      height: emString(BUBBLE_SIZE),
    };
    
    return (
      <BubbleDiv className={classNames("Bubble", {"tutorial-highlight": answer.tutorialVisible})} style={style}
        pose={this.state.pose} initialPose={ANIMATION_POSES.start}
        startPosition={this.props.startPosition} finishPosition={this.props.finishPosition} duration={BUBBLE_FLIGHT_DURATION}
        onPoseComplete={this.poseCompleted}
      >
        <div className={classNames("bubble-container",
              {"visible": true /*answer.visible*/}, {"active": true /*answer.active*/}, {"show-feedback": this.state.clicked})}
             style={bubbleContainerStyle}

             onMouseUp={this.onClick}
             onTouchEnd={this.onClick}

             // onClick={this.onClick}
        >
          <div className="vertical-helper" />
          <div className="content">{answer.content}</div>
          {!answer.correct && <div className={'feedback error'}><div className="vertical-helper" /><i className={'fas fa-times'} /></div>}
        </div>
      </BubbleDiv>
    );
  }

  poseCompleted = () => {
    if (this.state.pose === ANIMATION_POSES.moving) {
      this.setState({
        pose: ANIMATION_POSES.start,
      }, this.props.onMovementFinished.bind(this, this.props.answer));
    }
  };

  onClick = (e) => {
    let targetPosition = e.target.getBoundingClientRect();
    
    let targetRect = {
      x: targetPosition.x,
      y: targetPosition.y,
      width: targetPosition.width,
      height: targetPosition.height,
    };
    
    if (this.doubleTapping) {
      console.log('double');
      this.doubleTapping = false;
      clearTimeout(this.bubbleClicked);
      this.sendClickCallback(true);
      this.bubbleDoubleClicked(targetRect);
    } else {
      this.bubbleClicked(targetRect);
      this.doubleTapping = true;
      setTimeout(this.sendClickCallback.bind(this, false), DOUBLE_TAP_INTERVAL_MS)
    }
  
    e.stopPropagation();
    e.preventDefault();
  };
  
  bubbleClicked = (targetRect) => {
    if (this.props.answer.correct) {
      Sounds.success.play();

      let x = targetRect.x + targetRect.width / 2;
      let y = targetRect.y + targetRect.height / 2;
      this.setState({
        pose: ANIMATION_POSES.start,
        clicked: true,
      });
      this.burst = this._createBurst(x, y, targetRect.width / 2, targetRect.width / 15).play();
      this.circle = this._createCircle(x, y, targetRect.width, targetRect.width / 2).play();
    } else {
      this.setState({
        pose: ANIMATION_POSES.paused,
        clicked: true,
      });
      Sounds.error.play()
    }

    setTimeout(this.props.onMovementFinished.bind(this, this.props.answer), FEEDBACK_DURATION_MS);
  };
  
  bubbleDoubleClicked = (targetRect) => {
    if (this.props.answer.correct) {
      let x = targetRect.x + targetRect.width / 2;
      let y = targetRect.y + targetRect.height / 2;
      this.burstDouble = this._createDoubleClickBurst(x, y, targetRect.width / 2).play();
    }
  };
  
  sendClickCallback = (doubleTapped = false) => {
    this.props.clickCallback(this.props.answer, this.props.index, doubleTapped);
  };

  _createBurst = (x, y, radius, childrenRadius) => {
    return new mojs.Burst({
      left: 0, top: 0,
      x, y,
      radius:   { 4: radius },
      angle:    45,
      count:    20,
      timeline: { delay: 300 },
      children: {
        radius:       childrenRadius,
        fill:         '#FD7932',
        scale:        { 1: 0, easing: 'quad.in' },
        pathScale:    [ .8, null ],
        degreeShift:  [ 13, null ],
        duration:     [ 500, 700 ],
        easing:       'quint.out'
      },
      onStart: () => {
        this.burst.el.style.zIndex = 10;
      },
      onComplete: () => {
        this.burst.el.style.zIndex = -1;
      },
    });
  };

  _createCircle = (x, y, width, radius) => {
    return new mojs.Shape({
      left: 0, top: 0,
      x, y,
      stroke:   '#FF9C00',
      strokeWidth: { [2 * width] : 0 },
      fill:       'none',
      scale:      { 0: 1 },
      radius:     radius,
      duration:   400,
      easing:     'cubic.out',
      onStart: () => {
        this.circle.el.style.zIndex = 10;
      },
      onComplete: () => {
        this.circle.el.style.zIndex = -1;
      },
    });
  };

  _createDoubleClickBurst = (x, y, radius) => {
    return new mojs.Burst({
      left: 0, top: 0,
      x, y,
      radius:   { 0: radius },
      angle:    'rand(0, 360)',
      count:    10,
      timeline: { delay: 0 },
      children: {
        shape: 'line',
        radius: radius / 10,
        strokeWidth: radius / 10,
        strokeLinecap: 'round',
        stroke: { 'rgb(255, 120, 0)': 'rgb(0, 198, 24)'  },
        fill:         { 'rgb(255, 120, 0)': 'rgb(0, 198, 24)'  },
        scale:        { 1: 0, easing: 'quad.in' },
        pathScale:    [ .8, null ],
        degreeShift:  [ 13, null ],
        duration:     [ 700, 700 ],
        easing:       'quint.out'
      },
      onStart: () => {
        this.burstDouble.el.style.zIndex = 12;
      },
      onComplete: () => {
        this.burstDouble.el.style.zIndex = -1;
      },
    });
  };
}