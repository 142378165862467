import React, { Component } from 'react';

import './sprint/controllers/SprintController'
import './App.scss';
import SprintController from "./sprint/controllers/SprintController";
import {Route, BrowserRouter} from "react-router-dom";



class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Route path={`/:instanceName?`} component={SprintController}/>
        </BrowserRouter>
        {/*<SprintController instanceName="test" exercises={this.props.exercises}/>*/}
      </div>
    );
  }
}

export default App;
