import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Card from "../../../components/Card/Card";
import AnimationCorrectExplosion from "../../../animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion";
import AnimationIncorrectExplosion from "../../../animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion";

import './RevealExerciseCard.scss';

export default class RevealExerciseCard extends Component {
  static propTypes = {
    isCorrect: PropTypes.bool,
    isShowingFeedback: PropTypes.bool,
    isBlurred: PropTypes.bool,
    isActive: PropTypes.bool,
    wasActivated: PropTypes.bool,

    content: PropTypes.string,
    index: PropTypes.number,

    onClick: PropTypes.func,
  };

  cardRef = React.createRef();

  render() {
    const {isShowingFeedback, isBlurred, isCorrect, wasActivated, content} = this.props;

    let animationElement;
    if (this.cardRef.current) {
      // debugger;
      const buttonRect = this.cardRef.current.ref.current.getBoundingClientRect();
      let feedbackPosition = {
        x: buttonRect.x + buttonRect.width / 2,
        y: buttonRect.y + buttonRect.height / 2,
      };

      if (isCorrect) {
        animationElement = <AnimationCorrectExplosion visible={isShowingFeedback && wasActivated}
          x={feedbackPosition.x} y={feedbackPosition.y}
        />;
      } else {
        animationElement = <AnimationIncorrectExplosion visible={isShowingFeedback && wasActivated}
          x={feedbackPosition.x} y={feedbackPosition.y}
        />;
      }
    }

    let color = Card.COLORS.MAIN;
    if (isShowingFeedback) {
      if (isCorrect) {
        color = Card.COLORS.SUCCESS;
      } else {
        color = Card.COLORS.FAILURE;
      }
    }

    return <Card className="RevealExerciseCard" ref={this.cardRef} color={color}
      onClick={this.clicked} isBlurred={isBlurred} isClickable={!isShowingFeedback}>
      {animationElement}
      <p>{content}</p>
    </Card>
  }

  clicked = (event) => {
    const {onClick, index, isActive} = this.props;
    if (isActive) {
      event.persist();

      onClick(index, event);
    }
  };
}